/* Add these new styles at the top of your file */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .app-container {
    max-width: 800px;
  }
}

.sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

/* Existing styles */
.app-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
  margin-bottom: 1.5rem;
}

h1 {
  color: #003366;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2rem;
  }
}

.start-interview {
  text-align: center;
}

button {
  background-color: #003366;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #004c99;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.interview-in-progress {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 1rem;
}

.interview-image {
  max-width: 100%;
  height: auto;
}

.interview-status {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  color: #003366;
}

.volume-indicator {
  height: 5px;
  background-color: #003366;
  margin-bottom: 20px;
}

.transcript {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #cccccc;
  padding: 10px;
  margin-bottom: 20px;
  color: #003366;
}

.message {
  margin-bottom: 10px;
}

.message.human {
  color: #003366;
}

.message.ai {
  color: #006600;
}

.end-call {
  background-color: #cc0000;
  display: block;
  margin: 0 auto;
}

.end-call:hover {
  background-color: #ff0000;
}

.sound-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin: 1rem 0;
}

.sound-circle {
  background-color: #3498db;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
}

.sound-circle.active {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.auth-buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media (max-width: 480px) {
  .auth-buttons {
    position: static;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.sign-in-message {
  text-align: center;
  margin-top: 50px;
}

.sign-in-message p {
  margin-bottom: 20px;
}
